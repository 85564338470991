import { Controller } from "@hotwired/stimulus"

declare global {
    var isSidebarOpen: boolean
}

export default class extends Controller {
    static targets: string[] = ["toggleButton", "sidebarNav", "blackoutDiv"]
    declare readonly sidebarNavTarget: HTMLDivElement
    declare readonly blackoutDivTarget: HTMLDivElement
    declare readonly hasSidebarNavTarget: boolean
    declare readonly hasBlackoutDivTarget: boolean
    
    openWidth: string = "w-52"
    
    connect() {
        if (globalThis.isSidebarOpen === undefined) {
            globalThis.isSidebarOpen = this.sidebarNavTarget.classList.contains(this.openWidth)
        }
        if (globalThis.isSidebarOpen) this.open()
        else this.close()
    }
    
    toggle() {
        if (globalThis.isSidebarOpen) this.close()
        else this.open()
    }
    
    close() {
        this.sidebarNavTarget.classList.remove(this.openWidth)
        this.sidebarNavTarget.classList.add("w-0")
        this.blackoutDivTarget.classList.add("hidden")
        globalThis.isSidebarOpen = false;
        document.cookie = 'sidebar_open=false; path=/'
    }

    closeIfMobile() {
        if (this.blackoutDivTarget.checkVisibility()) {
            this.close();
        }
    }
    
    open() {
        this.sidebarNavTarget.classList.add(this.openWidth)
        this.sidebarNavTarget.classList.remove("w-0")
        this.blackoutDivTarget.classList.remove("hidden")
        globalThis.isSidebarOpen = true;
        document.cookie = 'sidebar_open=true; path=/'
    }
}
