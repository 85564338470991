import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLDivElement> {
  declare readonly triggerValue: String
  declare optionElement: HTMLInputElement | null
  static values = {
    trigger: String
  }

  connect() {
    this.optionElement = document.querySelector(`[data-dynamic-id=${this.triggerValue}]`)
    if (!(this.optionElement instanceof HTMLInputElement)) return
    document.addEventListener('change', this.handleChanged.bind(this))
    this.handleChanged()
  }

  handleChanged() {
    if (!(this.optionElement instanceof HTMLInputElement)) return
    if (this.optionElement.checked) {
      this.element.style.display = 'block'
      this.element.querySelectorAll('input').forEach(inputElement => {
        if (!inputElement.id.startsWith('submission_answers_attributes')) return
        inputElement.disabled = false
      })
    } else {
      this.element.style.display = 'none'
      this.element.querySelectorAll('input').forEach(inputElement => {
        if (!inputElement.id.startsWith('submission_answers_attributes')) return
        inputElement.disabled = true
      })
    }
  }
}
