import ChartController from "./chart_controller"

export default class extends ChartController {
  initialize() {
    this.config = {
      type: 'bar',
      options: {
        plugins: {
          title: {
            text: 'Median Resolution Time by Form',
            display: true
          }
        },
        scales: {
          y: {
            title: {
              text: 'Resolution Time (days)',
              display: true
            }
          }
        }
      },
      data: {
        labels: this.dataValue.map(row => String(row.title)),
        datasets: [
          {
            label: 'All Time',
            data: this.dataValue.map(row => row.median_days_all_time),
            backgroundColor: this.colors[0]
          },
          {
            label: 'Past Year (rolling)',
            data: this.dataValue.map(row => row.median_days_year),
            backgroundColor: this.colors[8]
          },
          {
            label: 'Past Month (rolling)',
            data: this.dataValue.map(row => row.median_days_month),
            backgroundColor: this.colors[4]
          },
          {
            label: 'Past Week (rolling)',
            data: this.dataValue.map(row => row.median_days_week),
            backgroundColor: this.colors[2]
          }
        ]
      }
    }
  }
}