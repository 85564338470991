import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  highlightClasses = ["border-dashed", "border-yellow-400", "bg-yellow-200", "text-yellow-400"]
  normalClasses = ["border-hidden"]
  refcounter = 0
  
  connect() {
  }

  dragStart(event: DragEvent) {
    if (event.target === null) return
    if (!(event.target instanceof HTMLDivElement)) {console.error(event.target, "is not a HTMLElement"); return}
    event.dataTransfer.setData("text/plain", event.target.dataset.draggedPath)
    event.dataTransfer.effectAllowed = "move"
  }

  dragOver(event: DragEvent) {
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }

  dragEnter(event: DragEvent) {
    if (!(event.currentTarget instanceof HTMLElement)) {console.error(event.target, "is not a HTMLElement"); return}
    event.preventDefault()
    this.refcounter += 1
    if (this.refcounter > 0) {
      event.currentTarget.classList.add(...this.highlightClasses)
      event.currentTarget.classList.remove(...this.normalClasses)
    }
  }

  dragLeave(event: DragEvent) {
    if (!(event.currentTarget instanceof HTMLElement)) {console.error(event.target, "is not a HTMLElement"); return}
    event.preventDefault()
    this.refcounter -= 1
    if (this.refcounter === 0) {
      event.currentTarget.classList.add(...this.normalClasses)
      event.currentTarget.classList.remove(...this.highlightClasses)
    }
  }

  drop(event: DragEvent) {
    if (!(event.currentTarget instanceof HTMLElement)) {console.error(event.target, "is not a HTMLElement"); return}
    const path = event.dataTransfer.getData("text/plain")
    const targetOrder = event.currentTarget.dataset.targetOrder
    
    htmx.ajax('PATCH', path, {
      target: '#chapter_elements',
      values: {
        'chapter_element[order]': targetOrder
      },
      select: '#chapter_elements',
      swap: 'outerHTML'
    })
  }
}
