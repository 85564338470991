import ChartController from "./chart_controller"

export default class extends ChartController {
  initialize() {
    this.config = {
      type: 'bar',
      options: {
        plugins: {
          title: {
            text: this.titleValue,
            display: true
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            title: {
              text: this.element.dataset.yLabel,
              display: true,
            }
          }
        }
      },
      data: {
        labels: this.dataValue.map(row => String(row.title)),
        datasets: [
          {
            label: 'Resolved/Approved',
            data: this.dataValue.map(row => row.count_green),
            backgroundColor: '#22c55e'
          },
          {
            label: 'Pending',
            data: this.dataValue.map(row => row.count_amber),
            backgroundColor: '#f59e0b'
          },
          {
            label: 'Denied/Needs Investigation',
            data: this.dataValue.map(row => row.count_red),
            backgroundColor: '#ef4444'
          },

        ]
      },
    }
  }
}