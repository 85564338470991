import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets: string[] = ["statusButton", "commentButton", "holdButton"]
  declare readonly statusButtonTarget: HTMLInputElement
  declare readonly holdButtonTarget: HTMLInputElement
  declare readonly hasHoldButtonTarget: boolean
  declare readonly commentButtonTarget: HTMLInputElement
  declare originalText: string
  declare holdOriginalText: string
  connect() {
    this.originalText = this.statusButtonTarget.value
    if (this.hasHoldButtonTarget) {
      this.holdOriginalText = this.holdButtonTarget.value
    }
  }

  update(event: Event) {
    if (!(event.target instanceof HTMLElement)) return
    if (event.target.innerText) {
      this.statusButtonTarget.value = this.originalText + " with Comment"
      if (this.hasHoldButtonTarget) {
        this.holdButtonTarget.value = this.holdOriginalText + " with Comment"
      }
      this.commentButtonTarget.disabled = false
    } else {
      this.statusButtonTarget.value = this.originalText
      if (this.hasHoldButtonTarget) {
        this.holdButtonTarget.value = this.holdOriginalText
      }
      this.commentButtonTarget.disabled = true
    }
  }
}
