import { Controller } from "@hotwired/stimulus"

function elm(html: string) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  return wrapper.firstChild;
}

export default class extends Controller<HTMLDivElement> {
  declare count: number
  declare index: number
  declare namePrefix: string
  static targets = ["signatureList"]
  declare signatureListTarget: HTMLDivElement

  connect() {
    this.count = 0;
    this.index = 0;
    this.namePrefix = this.element.dataset.namePrefix

    if (this.element.dataset.required == "true") { this.add() }
  }

  add() {
    const nameName = `${this.namePrefix}[signatures_attributes][${this.index}][name]`
    const signatureDataName = `${this.namePrefix}[signatures_attributes][${this.index}][base64_uri]`
    const id = "signatureElement_" + String(Math.floor(Date.now() + (Math.random() * 100000)))

    const signatureElement = elm(`<div id="${id}" class="flex flex-col w-min h-min border">
        <div class="flex">
          <input placeholder="Name" class="shadow-none border-none" type="text" name=${nameName} required="true">
          <button type="button" class="btn btn-danger" data-delete-target="${id}" data-action="multi_signature#remove">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div class="bg-slate-100 shadow-inner inline-block relative" data-controller="signature" data-name=${signatureDataName}>
          <button class="absolute left-0 bottom-0 p-1 text-red-500 font-bold" type="button" data-signature-target="clearButton" data-action="signature#clear">Clear</button>
          <button class="absolute right-0 bottom-0 p-1 text-green-500 font-bold" type="button" data-signature-target="confirmButton" data-action="signature#confirm">Confirm</button>
        </div>
      </div>`)

    this.count += 1
    this.index += 1
    this.signatureListTarget.appendChild(signatureElement)
  }

  remove(e: Event) {
    if (!(e.currentTarget instanceof HTMLButtonElement)) { console.error("event.currentTarget is not a HTMLButtonElement"); return }
    const elementToRemove = document.getElementById(e.currentTarget.dataset.deleteTarget)
    elementToRemove.remove()
    this.count -= 1;

    if (this.count === 0 && this.element.dataset.required == "true") {
      this.add()
    }
  }
  
}

