import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLDivElement> {
  static targets: string[] = ["content"]
  declare contentTarget: HTMLDivElement

  connect() {
    this.element.hidden = true
  }

  show(e: CustomEvent) {
    this.element.hidden = false
  }

  hide(e: CustomEvent) {
    this.element.hidden = true
    this.contentTarget.innerHTML = ""
  }
}