import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets: string[] = ["reorderForm"]
  declare readonly reorderFormTargets: HTMLFormElement[]

  highlightClasses = ["border-yellow-400", "bg-yellow-200"]
  
  connect() {
  }

  dragStart(event: DragEvent) {
    if (event.target === null) return
    if (!(event.target instanceof HTMLDivElement)) return
    if (event.dataTransfer === null) return
    const draggedData = {
      questionId: Number(event.target.dataset.questionId)
    }
    event.dataTransfer.setData("text/json", JSON.stringify(draggedData))
    event.dataTransfer.effectAllowed = "move"
  }

  dragOver(event: DragEvent) {
    if (event.dataTransfer === null) return
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }

  dragEnter(event: DragEvent) {
    if (!(event.currentTarget instanceof HTMLDivElement)) return
    event.preventDefault()
    event.currentTarget.classList.add(...this.highlightClasses)
  }

  dragLeave(event: DragEvent) {
    if (!(event.currentTarget instanceof HTMLDivElement)) return
    event.preventDefault()
    event.currentTarget.classList.remove(...this.highlightClasses)
  }

  drop(event: DragEvent) {
    if (event.dataTransfer === null) return
    if (!(event.currentTarget instanceof HTMLDivElement)) return
    event.preventDefault()

    const draggedData = JSON.parse(event.dataTransfer.getData("text/json"))
    const draggedQuestionId = draggedData.questionId
    const targetAfterQuestionId = event.currentTarget.dataset.questionAfter
    
    if (targetAfterQuestionId === undefined) return

    const reorderForm = this.reorderFormTargets.find(form => {return form.dataset.questionId == draggedQuestionId})
    if (reorderForm === undefined) return
    const targetInput = reorderForm.querySelector('input[name="target_index"]')
    if (!(targetInput instanceof HTMLInputElement)) return
    targetInput.value = targetAfterQuestionId

    reorderForm.requestSubmit()
  }
}
