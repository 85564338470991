import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets: string[] = ['checkbox']
  declare readonly checkboxTargets: HTMLInputElement[]

  select() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = true)
  }
  
  deselect() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
  }
}
