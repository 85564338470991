import { Controller } from "@hotwired/stimulus"

function elm(html: string) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  return wrapper.firstChild;
}

function fileListItem(name: string, captionName: string) { 
  return elm(`<div class="flex mt-2" data-controller="direct" data-filevalidator-target="fileListItem">
    <div class="relative bg-gray-300 w-full" >
      <div class="top-0 flex justify-center items-center w-full h-full z-30">
        <input class="hidden" type="file" name="${name}" data-direct-target="fileInput">
        <span class="z-20 whitespace-nowrap pl-2 md:pl-4 overflow-hidden text-xs md:text-base w-full" data-direct-target="filename"><i>No File Selected</i></span>
        <input name="${captionName}" type="text" class="opacity-75 z-20 w-full" placeholder="Caption">
      </div>
      <div class="absolute z-10 top-0 bg-blue-300 h-full transition-all	" style="width:0%" data-direct-target="progress"></div>
    </div>
    <button class="z-20 btn btn-danger" type="button" data-action="direct#destroy">Delete</button>
  </div>`) 
}

function fileListItemWithFor(name: string, captionName: string, forName: string, forValue: string) { 
  return elm(`<div class="flex mt-2" data-controller="direct" data-filevalidator-target="fileListItem">
  <div class="relative bg-gray-300 w-full" >
    <div class="top-0 flex justify-center items-center w-full h-full z-30">
      <input class="hidden" type="file" name="${name}" data-direct-target="fileInput">
      <span class="z-20 whitespace-nowrap pl-2 md:pl-4 overflow-hidden text-xs md:text-base w-full" data-direct-target="filename"><i>No File Selected</i></span>
        <input name="${captionName}" type="text" class="opacity-75 z-20 w-full" placeholder="Caption">
      <input type="hidden" name="${forName}" value="${forValue}" >
    </div>
    <div class="absolute z-10 top-0 bg-blue-300 h-full transition-all	" style="width:0%" data-direct-target="progress"></div>
  </div>
  <button class="z-20 btn btn-danger" type="button" data-action="direct#destroy">Delete</button>
</div>`) 
}

export default class extends Controller<HTMLDivElement> {
  static targets = ['fileList']
  declare fileListTarget: HTMLDivElement
  connect() { 
  }

  chooseFile() {
    if (this.element.dataset.forName && this.element.dataset.forValue) {
      this.fileListTarget.appendChild(fileListItemWithFor(
        this.element.dataset.name,
        this.element.dataset.captionName,
        this.element.dataset.forName,
        this.element.dataset.forValue
      ))
    } else {
      this.fileListTarget.appendChild(fileListItem(
        this.element.dataset.name, 
        this.element.dataset.captionName
      ))
    }
  }
}


