import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFieldSetElement> {
  static targets: string[] = ['checkbox']
  declare readonly checkboxTargets: HTMLInputElement[]
  connect() {
    this.validate();
  }

  validate() {
    if (this.element.dataset.required !== 'true') return

    const checkedCount = this.checkboxTargets.reduce((previousValue, currentValue) => {
      return previousValue + Number(currentValue.checked)
    }, 0)

    if (checkedCount == 0) {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.setCustomValidity("Please select at least one item.")
        checkbox.required = true
      })
    } else if (checkedCount > 0) {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.setCustomValidity("")
        checkbox.required = false
      })
    }
  }
}
