import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller<HTMLDivElement> {

  static targets = ['progress', 'filename', 'fileInput']
  declare progressTarget: HTMLDivElement
  declare filenameTarget: HTMLSpanElement
  declare fileInputTarget: HTMLInputElement
  declare directUploadUrl: string
  declare parentElement: HTMLElement

  async connect() {
    this.parentElement = this.element.parentElement
    this.directUploadUrl = this.element.parentElement.parentElement.dataset.directUploadUrl
    this.element.dataset.uploadComplete = "false"
    this.fileInputTarget.addEventListener('input', this.fileAdded.bind(this))
    this.element.dispatchEvent(new Event('upload-created', {bubbles: true}))
    this.openFileSelect()
  }

  openFileSelect() {
    this.element.dispatchEvent(new Event('upload-changed', {bubbles: true}))
    this.element.dispatchEvent(new Event('upload-select', {bubbles: true}))
    this.fileInputTarget.click()
  }

  destroy() {
    this.element.remove()
    this.parentElement.dispatchEvent(new Event('upload-changed', {bubbles: true}))
    this.parentElement.dispatchEvent(new Event('upload-destroyed', {bubbles: true}))
  }

  fileAdded(event: Event) {
    const file = this.fileInputTarget.files[0]
    this.filenameTarget.innerText = file.name
    const directUpload = new DirectUpload(file, this.directUploadUrl, this)
    directUpload.create(this.directUploadComplete.bind(this))
    this.element.dispatchEvent(new Event('upload-changed', {bubbles: true}))
    this.element.dispatchEvent(new Event('upload-file-added', {bubbles: true}))
  }

  directUploadComplete(error, blob) {
    if (error) {
      this.progressTarget.classList.remove('bg-blue-300', 'bg-green-300', 'bg-red-300')
      this.progressTarget.classList.add('bg-red-300')
      this.progressTarget.style.width = "100%"
      this.element.dispatchEvent(new Event('upload-failed', {bubbles: true}))
    } 
    else {
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = this.fileInputTarget.name
      hiddenInput.value = blob.signed_id
      this.fileInputTarget.insertAdjacentElement('beforebegin', hiddenInput)

      this.progressTarget.classList.remove('bg-blue-300', 'bg-green-300', 'bg-red-300')
      this.progressTarget.classList.add('bg-green-300')
      this.progressTarget.style.width = "100%"
      this.element.dataset.uploadComplete = "true"
      this.element.dispatchEvent(new Event('upload-succeded', {bubbles: true}))
    }

    this.fileInputTarget.remove()
    this.element.dispatchEvent(new Event('upload-changed', {bubbles: true}))
  }

  handleProgress(event: ProgressEvent) {
    this.progressTarget.style.width = 100 * (event.loaded / event.total) + "%"
  }

  // DirectUpload delegate
  directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest) {
    xhr.upload.addEventListener('progress', this.handleProgress.bind(this))
  }

}
