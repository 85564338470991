import { Controller } from "@hotwired/stimulus"

const apps = {
}

export default class extends Controller {
  static values = { props: Object, app: String }
  declare propsValue: Object
  declare appValue: string
  
  connect() {
    let props = this.propsValue
    props['authenticityToken'] = (document.head.querySelector('meta[name="csrf-token"') as HTMLMetaElement).content
    props['authenticityParam'] = (document.head.querySelector('meta[name="csrf-param"') as HTMLMetaElement).content
    const app = new apps[this.appValue]({
      target: this.element,
      props: props
    })
  }
}
