import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editor", "popup", "candidate"]

  // This is a mess I know. It reads whats in the trix editor, places the popup window and filters the candidates
  // update() is triggered on trix-change, trix-selection-change and scroll@window
  update(e) {
    const editor = this.editorTarget.editor
    const doc = editor.getDocument()
    const range = editor.getSelectedRange()
    if (range[0] !== range[1]) {
      this.popupTarget.hidden = true
      return
    }
    const text = doc.getStringAtRange([0, range[1]])
    const currentChar = doc.getStringAtRange([range[1]-1, range[1]])
    const currentCharIsValid = /[\w@]/.test(currentChar)

    if (!currentCharIsValid) {
      this.popupTarget.hidden = true
      return
    }

    const words = text.match(/[\w@]+/g)
    if (!words) { 
      this.popupTarget.hidden = true
      return
    }
    const lastWord = words[words.length - 1]
    if (!(lastWord[0] === "@")) { 
      this.popupTarget.hidden = true
      return;
    } else {
      let foundPosition = false
      let currentPosition = range[1]
      // One character in the string is not nessesarily one character in the Trix document model...
      // This is why we're doing this loop terribleness
      while (!foundPosition && currentPosition > 0) {
        const char = doc.getStringAtRange([currentPosition, currentPosition+1])
        if (char === '@') {
          foundPosition = true
          break
        }
        currentPosition -= 1
      }
      const rect = editor.getClientRectAtPosition(currentPosition)

      // Filter the candidates
      const nameQuery = lastWord.toUpperCase().replace('@', '')
      for (const candidate of this.candidateTargets) {
        const name = candidate.innerText.replace('@', '').toUpperCase()
        if (name.includes(nameQuery)) {
          candidate.hidden = false
          candidate.style.display = ''
        } else {
          candidate.hidden = true
          candidate.style.display = 'none'
        }
      }

      this.candidateTargets
      
      this.popupTarget.hidden = false
      this.popupTarget.style.top = rect.bottom + 'px'
      this.popupTarget.style.left = rect.left + 'px'
    }
  }

  select(e) {
    const editor = this.editorTarget.editor
    const doc = editor.getDocument()
    const sgid = e.target.dataset.sgid
    const attachment = new Trix.Attachment({sgid: sgid, content: `<span class="text-blue-500">${e.target.innerText}</span>`})

    const range = editor.getSelectedRange()

    // I'm not even going to try and handle when a range is selected.
    // The menu shouldn't even appear if a range is selected
    if (range[0] !== range[1]) {
      return
    }

    // Find which range of characters to delete
    let currentPosition = range[1];
    while (currentPosition > 0) {
      const char = doc.getStringAtRange([currentPosition-1, currentPosition])
      currentPosition -= 1;
      if (char === '@') {
        break
      }
    }

    // Delete the characters
    editor.setSelectedRange([currentPosition, range[1]])
    editor.deleteInDirection("backward")

    editor.insertAttachment(attachment)
  }
}
