import ChartController from "./chart_controller"

export default class extends ChartController {
  initialize() {
    this.config = {
      type: 'bar',
      options: {
        plugins: {
          title: {
            text: 'Form Submission Count',
            display: true
          }
        },
        scales: {
          y: {
            title: {
              text: 'Count',
              display: true
            }
          }
        }
      },
      data: {
        labels: this.dataValue.map(row => String(row.title)),
        datasets: [
          {
            label: 'All Time',
            data: this.dataValue.map(row => row.count_all_time),
            backgroundColor: this.colors[0]
          },
          {
            label: 'Past year (rolling)',
            data: this.dataValue.map(row => row.count_year),
            backgroundColor: this.colors[8]
          },
          {
            label: 'Past Month (rolling)',
            data: this.dataValue.map(row => row.count_month),
            backgroundColor: this.colors[4]
          },
          {
            label: 'Past Week (rolling)',
            data: this.dataValue.map(row => row.count_week),
            backgroundColor: this.colors[2]
          }
        ]
      }
    }
  }
}