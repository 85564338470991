import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFieldSetElement> {
  static targets: string[] = ['editor']
  declare readonly editorTarget: HTMLElement
  connect() {
    this.validate();
  }

  validate() {
    if (this.element.dataset.required !== 'true') {
      
      this.element.dataset.formvalidatorTarget = undefined
      return
    }

    this.element.dataset.formvalidatorTarget = 'field'
    const innerText = this.editorTarget.innerText
    if (innerText !== '' || this.element.checkVisibility() === false) {
      this.element.dataset.invalid = "false"
    } else {
      this.element.dataset.invalid = "true"
    }
  }
}
