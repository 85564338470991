import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets: string[] = ["menu"]
    declare readonly menuTarget: HTMLDivElement

    declare isOpen: boolean;

    connect() {
        this.close()
        document.addEventListener('click', this.handleDocumentClick.bind(this))
    }

    handleDocumentClick(e: Event) {
        if (e.target instanceof Element) {
            if (!this.element.contains(e.target)) this.close()
        }
    }

    close() {
        this.menuTarget.classList.add("hidden")
        this.isOpen = false
    }

    open() {
        this.menuTarget.classList.remove("hidden")
        this.isOpen = true
    }

    toggle() {
        if (this.isOpen) this.close()
        else this.open()
    }
}
