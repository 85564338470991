import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets: string[] = ["button", "container", "caret"]
    declare readonly buttonTarget: HTMLButtonElement
    declare readonly containerTarget: HTMLDivElement
    declare readonly caretTarget: HTMLElement

    declare isOpen: boolean
    declare isActive: boolean
    
    connect() {
        if (this.containerTarget.dataset.active === "true") this.isActive = true
        if (this.containerTarget.dataset.open === "true") this.open()
        else this.close()
    }

    toggle() {
        if (this.isOpen) this.close()
        else this.open()
    }
    
    open() {
        this.containerTarget.classList.add("max-h-full")
        this.containerTarget.classList.remove("max-h-0")

        if (this.isActive) {
            this.buttonTarget.classList.add("bg-transparent")
            this.buttonTarget.classList.remove("bg-slate-300")
        }

        this.caretTarget.classList.remove("fa-caret-right")
        this.caretTarget.classList.add("fa-caret-down")

        this.isOpen = true
    }

    close() {
        this.containerTarget.classList.remove("max-h-full")
        this.containerTarget.classList.add("max-h-0")

        if (this.isActive) {
            this.buttonTarget.classList.remove("bg-transparent")
            this.buttonTarget.classList.add("bg-slate-300")
        }

        this.caretTarget.classList.add("fa-caret-right")
        this.caretTarget.classList.remove("fa-caret-down")

        this.isOpen = false
    }
}
