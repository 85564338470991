import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLDivElement> {
  declare speed: number
  declare interval: number
  async connect() {
    console.log("Autoscroll connected")
    this.speed = 1;

    this.interval = setInterval(() => {
      let positionBefore = this.element.scrollTop
      this.element.scrollBy(0, this.speed)
      let positionAfter = this.element.scrollTop
      if (positionAfter - positionBefore === 0) this.speed = this.speed * -1
    }, 70)
  }

  async disconnect() {
    clearInterval(this.interval)
    console.log("Disconnecteed")
  }
}