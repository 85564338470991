import { Controller } from "@hotwired/stimulus"
import { Html5Qrcode } from "html5-qrcode"

export default class extends Controller<HTMLDivElement> {
  declare scanner: Html5Qrcode
  declare recentlyScanned: string[]
  declare endpointUrl: URL

  static values = {
    endpoint: String
  }
  static targets: string[] = ['select']
  declare readonly selectTarget: HTMLSelectElement
  declare readonly endpointValue: string

  async connect() {
    if (this.element.dataset.running == "true") { return }
    const cameras = await Html5Qrcode.getCameras()
    cameras.forEach(camera => {
      const optionElement = document.createElement('option')
      optionElement.value = camera.id
      optionElement.innerText = camera.label
      this.selectTarget.appendChild(optionElement)
    })

    this.endpointUrl = new URL(this.endpointValue)
    this.recentlyScanned = []
    this.scanner = new Html5Qrcode('reader', false)

    this.selectTarget.value = cameras[cameras.length - 1].id
    this.start()
  }

  async onScanSuccess(decodedText: string, decodedResult: any) {
    const idNumber = parseInt(decodedText)
    if (isNaN(idNumber)) { console.warn("QR Code is not a number"); return }
    this.endpointUrl.searchParams.set('equipment_id', idNumber.toString())
    await this.scanner.pause()
    htmx.ajax('GET', this.endpointUrl.toString())
  }

  async start() {
    let cameraId = this.selectTarget.value
    if (this.scanner.isScanning) { return }
    await this.scanner.start(cameraId, { fps: 10, qrbox: { width: 250, height: 250 }}, this.onScanSuccess.bind(this), this.onScanFailure.bind(this))
  }

  onScanFailure(error: any) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    // console.warn(`Code scan error = ${error}`);
  }

  async disconnect() {
    if (!this.scanner) { return }
    if (this.scanner.isScanning) await this.scanner.stop()
    delete this.scanner  }
}



