import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFieldSetElement> {
  static targets: string[] = ['fileListItem']
  declare fileListItemTargets: HTMLDivElement[]
  connect() {
    this.element.dataset.formvalidatorTarget = 'field'
    this.validate();
  }

  validate() {
    for (const fileListItem of this.fileListItemTargets) {
      if (fileListItem.dataset.uploadComplete == "false") {
        this.invalid("Please wait until all files have uploaded before submitting.")
        return
      }
    }

    if (this.element.dataset.required !== 'true') {
      this.valid()
      return
    }
      
    if (this.fileListItemTargets.length === 0) {
      this.invalid()
      return
    } else {
      this.valid()
      return
    }
  }

  invalid(reason?: string) {
    this.element.dataset.invalid = "true"
    if (reason) this.element.dataset.invalidReason = reason
    else this.element.dataset.invalidReason = ""
  }

  valid() {
    this.element.dataset.invalid = "false"
    this.element.dataset.invalidReason = ""
  }
}
