import { Controller } from "@hotwired/stimulus"
import * as Sentry from "@sentry/browser"

export default class extends Controller<HTMLButtonElement> {
  connect() {
    const integration = Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      autoInject: false,
      formTitle: "Give Feedback",
      submitButtonLabel: "Send Feedback",
      messagePlaceholder: "What happened? What did you expect to happen? Describe the feature you want."
    })
    
    Sentry.init({
      dsn: "https://40abaf6b53adaf265343be05ff62b704@o4507501601423360.ingest.us.sentry.io/4507501724499968",
      integrations: [
        integration,
      ],
    });
    
    //@ts-ignore
    integration.attachTo(this.element)
  }
}
