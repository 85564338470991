import { Controller } from "@hotwired/stimulus"
// @ts-ignore
import RangeSlider from "@spreadtheweb/multi-range-slider"

export default class extends Controller {
  declare slider: RangeSlider
  static targets: string[] = ['EDThreshold', 'DCThreshold', 'CBThreshold', 'BAThreshold'];
  declare readonly EDThresholdTarget: HTMLInputElement
  declare readonly DCThresholdTarget: HTMLInputElement
  declare readonly CBThresholdTarget: HTMLInputElement
  declare readonly BAThresholdTarget: HTMLInputElement
  connect() {
    this.slider = new RangeSlider("#rangeSlider1", {
      values: [0,this.EDThresholdTarget.value, this.DCThresholdTarget.value, this.CBThresholdTarget.value, this.BAThresholdTarget.value, 100],
      max: 100,
      colors: {
        points: ["#ef4444", "#f97316", "#94a3b8", "#06b6d4", "#22c55e"],
        rail: "white",
        tracks: ["#ef4444", "#f97316", "#94a3b8", "#06b6d4", "#22c55e"]
      }
    })

    this.slider.onChange(this.handleChanged.bind(this))
  }

  handleChanged(values: any) {
    this.EDThresholdTarget.value = values[1]
    this.DCThresholdTarget.value = values[2]
    this.CBThresholdTarget.value = values[3]
    this.BAThresholdTarget.value = values[4]
  }
}
