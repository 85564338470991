import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets: string[] = ["list", "item"]
  declare readonly listTarget: HTMLUListElement
  declare readonly itemTargets: HTMLLIElement[]
  connect() {
  }

  add() {
    this.listTarget.appendChild(createNewListItem())
  }

  remove(e: Event) {
    
    if (e.target instanceof HTMLButtonElement) {
      
      const targetElementId = e.target.dataset.removeTarget
      const targetElement = this.itemTargets.find(element => {return element.id === targetElementId})
      if (!(targetElement instanceof HTMLLIElement)) { return }
      targetElement.remove()
    }
  }
}

function createNewListItem() {
  const tempId = Date.now();
  const listItem = document.createElement("li")
  listItem.id = String(tempId)
  listItem.classList.add("flex")
  listItem.dataset.optionsTarget = "item"
  

  const textInput = document.createElement("input")
  textInput.type = "text"
  textInput.name = "question[options_text][]"
  textInput.value = ""

  const deleteButton = document.createElement("button")
  deleteButton.type = "button"
  deleteButton.dataset.removeTarget = String(tempId)
  deleteButton.dataset.action = "options#remove"
  deleteButton.innerText = "Delete"
  deleteButton.classList.add("btn", "btn-danger")

  listItem.appendChild(textInput)
  listItem.appendChild(deleteButton)
  return listItem
}
