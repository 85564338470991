import ChartController from "./chart_controller"

export default class extends ChartController {
  initialize() {

    let datasets = []
    let i = 0
    for (const [formName, count] of Object.entries(this.dataValue[0])) {
      if (formName === 'title') continue
      datasets.push({
        label: formName,
        data: this.dataValue.map(row => row[formName]),
        hidden: true,
        backgroundColor: this.colors[i % this.colors.length]
      })
      i++;
    }

    this.config = {
      type: 'bar',
      options: {
        plugins: {
          title: {
            text: 'Form Submissions by User',
            display: true
          }
        },
        scales: {
          y: {
            title: {
              text: 'Count',
              display: true
            }
          }
        }
      },
      data: {
        labels: this.dataValue.map(row => String(row.title)),
        datasets: datasets
      }
    }
  }
}